import {axiosInstance} from "./index";

//negocioService -> apis sin token

export const getProfesoresByCourse = async(id) => {
    if (id != undefined) {
        let profesores=[]
        await axiosInstance.get("/api/negocio/getProfesoresByCurso?idCurso="+id)
            .then( res1 => {
                profesores = res1.data
            })
            .catch(err => {
                console.log('hubo un error: ', err)
                profesores = []
            })

        return profesores
    }
    console.log("no me estas mandando una id")
}

export const getAsesoriasAbiertasByCurso = async (idCurso) => {
    if (idCurso != undefined) {
        let asesorias = []

        await axiosInstance.get("/api/negocio/getAsesoriasAbiertasByCurso?idCurso="+idCurso)
            .then(async res => {
                asesorias = await res.data.map( x => {
                    let str = x.fecha
                    x.fecha = str.replace("T00:00:00.000Z","");
                })
            })
            .catch(err => {
                console.log('hubo un error: ', err)
                asesorias = []
            })

        return asesorias
    }
    
}

/* Alumnos */
export const getAsesoriaByIdAsesoriaAbierta = async (idAsesoria) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/getAsesoriaByIdAsesoriaAbierta?idAsesoria=' + idAsesoria)
        .then(async res => {
            respuesta.operacion = true
            respuesta.asesoriaData = res.data[0];
            respuesta.asesoriaData.fecha = respuesta.asesoriaData.fecha.replace("T00:00:00.000Z","");
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            
        })

    return respuesta
}
// en 2 lados
export const getFoto = async (id) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/getFoto?id_profesor=' + id)
        .then(async res => {
            respuesta.operacion = true
            respuesta.url_foto = res.data
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const getInfoProfe = async (id) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getInfoProfe?idProfesor=' + id)
        .then(async res => {
            respuesta.operacion = true
            respuesta.profesorData = res.data;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const getCursosByProfesor = async (id) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getCursosByProfesor?idProfesor=' + id)
        .then(async res => {
            respuesta.operacion = true
            respuesta.cursos_profesor = res.data;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const getDisponibilidadProfesor = async (id) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getDisponibilidadProfesor?idProfesor=' + id)
        .then(async res => {
            respuesta.operacion = true
            respuesta.data = res.data;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const getCursos = async () => {
    var respuesta = {}
    await axiosInstance.get('/api/curso/getCursos')
        .then(async res => {
            respuesta.operacion = true
            respuesta.data = res.data;
        }).catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.error =err
        });

    return respuesta
}

export const login = async (correo, contrasena, tipo) => {
    let data = {
        status: 200
    };
    await axiosInstance.post('/api/negocio/login', {
        email: correo,
        contrasena,
        tipo
    }).then(async res => {
        data.status = res.status;
        data.data = res.data;
        data.user = res.user;
    }).catch(err => {
        console.log('hubo un error: ', err);
        const response = err.response;
        data.status = response.status ?? 400;
        data.data = response.data ?? [];
        data.user = response.user ?? [];
    });
    return data;
}

export const newProfesorPostulante = async (url) => {
    let data = {
        status: 200
    };
    await axiosInstance.post(url)
    .then(async res => {
        data.status = res.status;
        data.data = res.data;
    }).catch(err => {
        console.log('hubo un error: ', err);
        const response = err.response;
        data.status = 400;
        if (response) {
            data.status = response.status ?? 400;
            data.data = response.data;
        }
    })
    return data;
}


export const newAlumno = async (url) => {
    var respuesta = {}
    await axiosInstance.post(url)
        .then(async res => {
            respuesta.operacion = 1;
            respuesta.data = res.data;
        }).catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = 2;
        });
    return respuesta;
}

export const recuperarContrasena = async (correo) => {
    var respuesta = {}
    await axiosInstance.get('/api/negocio/recuperarcontrasena?email='+correo)
        .then(async res => {
            respuesta.operacion = true
            respuesta.data = res.data
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
           
        })

    return respuesta
}