<template>
  <v-container>
    <v-layout column allign-center>
      <template v-if="unirseAsesoria == true">
        <v-row justify="center">
          <p class="font-weight-black">INICIE SESIÓN O CREE UNA CUENTA PARA UNIRSE A LA ASESORÍA</p>
          <p class="font-weight-black">(SI CREA UNA CUENTA, LUEGO DEBE INICIAR SESIÓN)</p>
        </v-row>
      </template>
      <template v-if="crearAsesoria == true">
        <v-row justify="center">
          <p class="font-weight-black">INICIE SESIÓN O CREE UNA CUENTA PARA CREAR LA ASESORÍA</p>
          <p class="font-weight-black">(SI CREA UNA CUENTA, LUEGO DEBE INICIAR SESIÓN)</p>
        </v-row>
      </template>
      <v-form ref="form" v-model="valid">
        <v-text-field v-model="loginData.correo" :counter="50" :rules="correoRules" label="Correo" required></v-text-field>
        <v-text-field v-if="!recoverPasswordS" v-model="loginData.contrasena" type="password" :counter="25" :rules="contrasenaRules" label="Contraseña" required></v-text-field>
        <v-row justify="center">
          <v-btn v-if="!recoverPasswordS" :disabled="!valid" color="success" class="mr-4" @click="validate">Iniciar Sesión</v-btn>
          <v-btn v-if="!recoverPasswordS" :to="{name:'alumnocrearcuenta'}">Crear Cuenta</v-btn>
        </v-row>
        <p></p>
        <v-row justify="center">
          <v-btn v-if="!recoverPasswordS" color="error" class="mr-4" @click="recuperarContrasena">Olvide Mi Contraseña</v-btn>
           <v-btn  v-if="recoverPasswordS" color="error" class="mr-4" @click="recuperarContrasenaApi">Enviar Contraseña</v-btn>
        </v-row>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {login,recuperarContrasena} from '../service/negocioService'
import {getInfo, sendDocMaterial, validateEmail} from '../service/alumnoService'
export default {
  data () {

    return {
      recoverPasswordS: false,
      valid:false,

      loginData:{
        correo:"",
        contrasena:""
      },

      correoRules: [
        v => !!v || 'El correo debe ser valido',
        v => /.+@.+\..+/.test(v) || 'El correo debe ser valido',
      ],
      contrasenaRules: [
        v => !!v || 'E-mail is required',
        v => (v && v.length >= 8) || 'La contraseña debe ser mayor a 8 caracteres',
      ]
    }
  },
  computed:{
    ...mapState([
      'userData',
      'currentJWT',
      'procesoNoTengoCuenta',
      'unirseAsesoria',
      'crearAsesoria',
      'id_Asesoria',
      'errobj'
    ])
  },
  created() {
    this.verificarCuenta()
  },
  methods:{
    ...mapMutations([
      'setUserAccount',
      'changeUserNadie',
      'changeUserAlumno',
      'setJWT',
      'saveStateToLocalStorage'
    ]),
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.userExist()
      }
    },
    recuperarContrasena(){
      this.recoverPasswordS = true;
    },
    async recuperarContrasenaApi(){
      let me = this;
      let respuesta = await recuperarContrasena(me.loginData.correo)
      if(respuesta.data == false || respuesta.operacion == false){
        me.errobj.errtitulo = 'Error: El usuario no existe'
        me.errobj.errtexto = 'Verifique sus datos o Verifique su correo, por favor intentar nuevamente'
        me.errobj.errbox = true
      }else{
        me.errobj.errtitulo = 'Contraseña enviada al correo'
        me.errobj.errtexto = 'Verifique sus email e inicie sesión'
        me.errobj.errbox = true
      }
    },
    async userExist(){
      let me = this
      let respuesta = await login(me.loginData.correo, me.loginData.contrasena, 1);
      if (respuesta.status >= 400) {
        swal("Error", respuesta.data ? respuesta.data.message : "El correo y la contraseña no coinciden, por favor intentar nuevamente", "error");
      } else if (respuesta.data && !respuesta.data.success) {
        swal("Error", respuesta.data.message, "error");
      }

      if (respuesta.data && respuesta.data.success) {
        me.$store.state.userNadie = false;
        me.$store.state.userAlumno = true;
        me.$store.state.userProfesor = false;
        me.setJWT(respuesta.data.data);
        me.setUserAccount(respuesta.data.user);

        //guardar sesion
        me.saveStateToLocalStorage();

        me.obtener()
        if(me.procesoNoTengoCuenta == true){
          if(me.unirseAsesoria == true){
            me.procesoNoTengoCuenta = false;
            me.unirseAsesoria = false;
            me.saveStateToLocalStorage();
            
            me.$router.push({path:'/alumno/unirse_asesoria/' + me.id_Asesoria})
          } else if (me.crearAsesoria == true) {
            me.procesoNoTengoCuenta = false;
            me.crearAsesoria = false;
            me.saveStateToLocalStorage();
            me.$router.push({name:'crear_asesoria'})
          }
        }else{
          me.$router.push({name:'alumnoinicio'})
        }

        //MOSTRAR MENSAJE DE DOCUMENTO ENVIADO
        if(localStorage.getItem("matPendingDownload") != null){
          let respuesta = sendDocMaterial(localStorage.getItem("matPendingDownload"), me.currentJWT.id, me.currentJWT.token, me.loginData.correo)
          swal("¡EXITO!", 'Se envio el Material a su correo', "success");
          localStorage.removeItem('matPendingDownload');
        }
      }
    },
    async obtener(){
      var me = this

      var respuesta = await getInfo(me.currentJWT.id, me.currentJWT.token, 1)
      if(respuesta.operacion){
        me.userData.nombre = respuesta.nombre
        me.userData.apellido = respuesta.apellido
        me.userData.dni = respuesta.dni
        me.userData.universidad = respuesta.universidad
        me.userData.celular = respuesta.celular
        me.userData.correo = respuesta.correo
        me.saveStateToLocalStorage();
      }else{
        me.$store.state.errobj.errtitulo = 'Error:'
        me.$store.state.errobj.errtexto = 'No se pudo obtener los datos'
        me.$store.state.errobj.errbox = true
      }
    },
    async verificarCuenta() {
      const { token, action } = this.$route.query
      if (action == 'validation-email' && token) {
        const respuesta = await validateEmail(token)
        this.$store.state.errobj.errtitulo = 'Mensaje:'
        this.$store.state.errobj.errtexto = respuesta.message;
        this.$store.state.errobj.errbox = true
      }
    }
  }
}
</script>
