<template>
  <v-container>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid">

          <v-row>
            
            <v-col cols="12">
              <v-text-field 
                v-model="nuevoProfesor.nombre" 
                :counter="50" 
                :rules="nombreRules"
                label="Nombres"
                required 
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field 
                v-model="nuevoProfesor.apellido" 
                :counter="50" 
                :rules="apellidoRules"
                label="Apellidos"
                required 
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <template>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="nuevoProfesor.nacimiento"
                        :rules="dateRules"
                        label="Fecha de nacimiento"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="nuevoProfesor.nacimiento" 
                    max="2001-12-30"
                    min="1985-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </template>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field 
                type="number" 
                :rules="dniRules"
                v-model.number="nuevoProfesor.dni" 
                label="DNI"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-select
                v-model="nuevoProfesor.universidad"
                :items="universidades"
                :rules="universidadRules"
                item-value="value"
                chips
                label="Selecciona una universidad"
              ></v-select>
            </v-col>

            <v-col cols="12" lg="6">
              <v-select
                v-model="nuevoProfesor.ciclo_actual"
                :items="ciclos"
                :rules="cicloRules"
                label="Ciclo Actual"
                chips
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field 
                v-model="nuevoProfesor.carrera"
                :counter="50" 
                :rules="carreraRules"
                label="Carrera"
                required 
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field 
                type="number"
                :rules="celularRules"
                v-model.number="nuevoProfesor.celular" 
                label="Celular"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                v-model="nuevoProfesor.correo"
                :rules="correoRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                auto-grow
                rows=1
                v-model="nuevoProfesor.descripcion"
                :counter="1400"
                label="Describe tu experiencia como tutor"
                hint="Que cursos desea enseñar, su nivel en ellos, experiencia enseñando, y lo que considere relevante"
                required
              ></v-textarea>
            </v-col>

          </v-row>

          <v-row justify="center">
            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Postular</v-btn>
            <v-btn color="warning" class="mr-4" :to="{name:'profesorlogin'}">Regresar</v-btn>
          </v-row>

        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {newProfesorPostulante} from '../service/negocioService'
export default {
  data () {
    return {

      nuevoProfesor:
      {
        nombre:'',
        apellido:'',
        nacimiento:null,
        dni:'',
        universidad:null,
        carrera:'',
        ciclo_actual:null,
        celular:null,
        correo:'',
        descripcion:'',
      },
      valid: false,

      universidades:[
        {text:'USIL - Universidad San Ignacio de Loyola', value:'USIL'},
        {text:'UPC - Universidad Peruana de Ciencias Aplicadas', value:'UPC'}
      ],
      ciclos:[
        {text:'1',value:1},
        {text:'2',value:2},
        {text:'3',value:3},
        {text:'4',value:4},
        {text:'5',value:5},
        {text:'6',value:6},
        {text:'7',value:7},
        {text:'8',value:8},
        {text:'9',value:9},
        {text:'10',value:10},
      ],
      nombreRules:[
        v => !!v || 'Nombre es necesario',
        v => (v && v.length <= 50) || 'El Nombre debe ser menor a 10 letras'
      ],
      apellidoRules:[
        v => !!v || 'Apellidos es necesario',
        v => (v && v.length <= 50) || 'Los Apellidos deben ser menor a 50 letras'
      ],
      carreraRules:[
        v => !!v || 'Carrera es necesaria',
        v => (v && v.length <= 50) || 'La Carrera debe ser menor a 50 letras'
      ],
      dniRules:[
        v => !!v || 'El DNI es necesario',
        v => !(v && ! /^[0-9]{8}$/.test(v)) || 'El DNI debe tener 8 digitos'
      ],
      celularRules:[
        v => !!v || 'El Celular es necesario',
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos'
      ],
      correo: '',
      correoRules: [
        v => !!v || 'E-mail es necesario',
        v => /.+@.+\..+/.test(v) || 'El E-mail tiene que ser valido',
        v => (v && v.length <= 50) || 'El correos debe ser menor a 50 letras'
      ],
      cicloRules: [
        v => !!v || 'El campo es necesario',
      ],
      universidadRules: [
        v => !!v || 'El campo es necesario',
      ],
      dateRules: [
        v => !!v || 'El campo es necesario',
      ],
      date: null,
      menu2: false,
    }
  },
  computed: {
    ...mapState([
      'errobj'
    ])
  },
  mounted () {
    this.verifyUser();
  },
  watch: {
    menu2 (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods:{
    save (date) {
      this.$refs.menu.save(date)
    },
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.postularProfesor()
      }
    },
    verifyUser () {
      if (this.$store.state.currentJWT?.token && !this.$store.state.userNadie) {
        if (this.$store.state.userProfesor) {
          this.$router.push({ name:'profesorinicio' });
        } else {
          this.$router.push({ name:'alumnoinicio' });
        }
      }
    },
    async postularProfesor(){
      let me = this;
      const newURL = this.crearURL();
      const response = await newProfesorPostulante(newURL);
      me.errobj.errbox = false;
      if (response.status >= 400) {
        swal("Error", response.data ? response.data.message : "Ocurrió un error al momento de intentar postularse", "error");
      } else if (response.data && !response.data.success) {
        swal("Error", response.data.message, "error");
      } else if (response.data && response.data.success) {
        swal("Éxito", response.data.message, "success");
        this.$router.push({ name:'profesorlogin' });
      }
    },
    crearURL(){
      var me = this;
      var newURL;
      newURL = '/api/profesor/newProfesorPostulante?nombre=' + me.nuevoProfesor.nombre
      newURL = newURL + '&apellido=' + me.nuevoProfesor.apellido
      newURL = newURL + '&celular=' + me.nuevoProfesor.celular.toString()
      newURL = newURL + '&dni=' + me.nuevoProfesor.dni.toString()
      newURL = newURL + '&universidad=' + me.nuevoProfesor.universidad
      newURL = newURL + '&correo=' + me.nuevoProfesor.correo.toLowerCase().trim()
      newURL = newURL + '&nacimiento=' + me.nuevoProfesor.nacimiento
      newURL = newURL + '&carrera=' + me.nuevoProfesor.carrera
      newURL = newURL + '&ciclo_actual=' + me.nuevoProfesor.ciclo_actual.toString()
      newURL = newURL + '&descripcion=' + me.nuevoProfesor.descripcion
      return newURL
    },
    inArray(needle,haystack){
      var count=haystack.length;
      for(var i=0;i<count;i++)
      {
        if(haystack[i]===needle){return true;}
      }
      return false;
    },
    msgError(num,msg){
      var me = this;
      switch(num){
        case 0:
          me.errobj.errtitulo = "Postulado correctamente";
          me.errobj.errtexto = "Se ha postulado correctamente al sistema";
          me.limpiar()
          break;
        case 1:
          me.errobj.errtitulo = "Error: Los datos ya existen";
          me.errobj.errtexto = "El dni, celular, o correo ya existen";
          break;
      }
      me.errobj.errbox = true;
    },
    limpiar(){
      let me = this;
      me.nuevoProfesor.nombre = '';
      me.nuevoProfesor.apellido = '';
      me.nuevoProfesor.nacimiento = null;
      me.nuevoProfesor.dni = '';
      me.nuevoProfesor.universidad = null;
      me.nuevoProfesor.carrera = '';
      me.nuevoProfesor.ciclo_actual = null;
      me.nuevoProfesor.celular = null;
      me.nuevoProfesor.correo = '';
      me.nuevoProfesor.descripcion = '';
    }
  }
}
</script>
