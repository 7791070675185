<template>
  <v-form>
    <v-container>

      <v-dialog v-model="msgbox" width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="msgbox = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-toolbar>
        <v-toolbar-title>Mi Perfil</v-toolbar-title>        
      </v-toolbar>
      <v-card>
        <v-row>
          <v-divider
            class="mx-4"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" md="5">
            <v-text-field
              readonly
              v-model="alumnoData.nombre"
              label="Nombre"
              outlined
            ></v-text-field>
            <v-text-field
              readonly
              v-model="alumnoData.universidad"
              label="Universidad"
              outlined
            ></v-text-field>
          </v-col>
          <v-divider
            class="mx-4"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" md="5">
            <v-form ref="form" v-model="valid">
              <v-text-field
                :readonly="deshabilitado"
                v-model.number="alumnoData.celular"
                label="Celular"
                type="number"
                outlined
                :rules="celularRules"
              ></v-text-field>
              <v-text-field
                :readonly="deshabilitado"
                v-model="alumnoData.correo"
                label="Correo"
                outlined
                :rules="correoRules"
              ></v-text-field>
              <h2>Cambiar Contraseña</h2>
              <v-text-field
                :readonly="deshabilitado"
                v-model="alumnoData.contrasena_old"
                type="password"
                label="Contraseña Actual"
                outlined
                hint="La contraseña debe ser mayor a 8 caracteres"
              ></v-text-field>
              <v-text-field
                :readonly="deshabilitado"
                v-model="alumnoData.contrasena_new"
                type="password"
                label="Contraseña Nueva"
                outlined
                hint="La contraseña debe ser mayor a 8 caracteres"
              ></v-text-field>

              <template v-if="editando">
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Guardar</v-btn>
                <v-btn color="error" class="mr-4" @click="cancelar">Cancelar</v-btn>
              </template>
              <template v-else>
                <v-btn color="success" class="mr-4" @click="editar">Editar</v-btn>
              </template>
            </v-form>
          </v-col>  
        </v-row>
      </v-card>




    </v-container>
  </v-form>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {getInfo, updateAlumno} from '../../service/alumnoService'

export default {
  data () {
    return {
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',
      valid: false,
      editando: false,
      deshabilitado: true,
      alumnoData:{
        nombre: '',
        universidad: '',
        celular: -1,
        correo: '',
        contrasena_old: '',
        contrasena_new: ''
      },
      auxData:{
        celular: -1,
        correo: '',
      },
      celularRules:[
        v => !!v || 'El Celular es necesario',
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos'
      ],
      correoRules: [
        v => !!v || 'El Correo es necesario',
        v => /.+@.+\..+/.test(v) || 'El Correo tiene que ser valido',
        v => (v && v.length <= 50) || 'El correos debe ser menor a 50 letras'
      ]
      //
    }
  },
  computed:{
    ...mapState([
      'currentJWT'
      ])
  },
  created(){
      this.obtener();
  },
  methods:{
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.validar()
      }
    },
    async obtener(){
      var me = this
      var respuesta = await getInfo(me.currentJWT.id, me.currentJWT.token, 1)
      if (respuesta.operacion) {
        me.alumnoData.nombre = respuesta.nombre
        me.alumnoData.universidad = respuesta.universidad
        me.alumnoData.celular = respuesta.celular
        me.alumnoData.correo = respuesta.correo
        
        me.auxData.celular = respuesta.celular
        me.auxData.correo = respuesta.correo
      } else {
        me.$store.state.errobj.errtitulo = 'Error:'
        me.$store.state.errobj.errtexto = respuesta.msgtexto;
        me.$store.state.errobj.errbox = true
      }
    },
    editar(){
      this.editando=true;
      this.deshabilitado=false;
    },
    validar(){
      let me = this;
      if(me.alumnoData.contrasena_old.length == 0
      && me.alumnoData.contrasena_new.length == 0){
        me.msgbox = true,
        me.msgtitulo = 'Ingrese Contraseña'
        me.msgtexto = 'Para poder modificar "Ciclo Actual", "Celular", y/o "Correo" debe ingresar su contraseña en "Contraseña Actual"'
      }else if(me.alumnoData.contrasena_old.length < 8){
        me.msgbox = true,
        me.msgtitulo = 'Contraseña Invalida'
        me.msgtexto = 'Su Contraseña Actual es incorrecta'
      }else if(me.alumnoData.contrasena_new.length == 0){
        me.guardar(0)
      }else if(me.alumnoData.contrasena_new.length < 8){
        me.msgbox = true,
        me.msgtitulo = 'Contraseña Nueva Invalida'
        me.msgtexto = 'Su Contraseña Nueva debe tener al menos 8 caracteres'
      }else if (me.alumnoData.contrasena_new.length >= 8){
        me.guardar(1)
      }else{
        console.log('Situacion no esperada, re-armar algoritmo')
      }
    },
    async guardar(num){
      let me = this;
      var newURL = '/api/alumno/updateAlumno?' 

      if(me.alumnoData.correo.length == 0){
        newURL = newURL + '&correo=' +  me.auxData.correo
      }else{
        newURL = newURL + '&correo=' +  me.alumnoData.correo
      }

      if(me.alumnoData.celular.length == 0){
        newURL = newURL + '&celular=' +  me.auxData.celular
      }else{
        newURL = newURL + '&celular=' +  me.alumnoData.celular
      }

      switch(num){
        case 0:
          newURL = newURL + '&contrasenaAnterior=' + me.alumnoData.contrasena_old
          + '&contrasenaActual=' + me.alumnoData.contrasena_old
          break;

        case 1:
          newURL = newURL + '&contrasenaAnterior=' + me.alumnoData.contrasena_old
          + '&contrasenaActual=' + me.alumnoData.contrasena_new
          break;
      }

      newURL = newURL + '&token=' + me.currentJWT.token + '&id=' + me.currentJWT.id
      console.log(newURL)

      var respuesta = await updateAlumno(newURL, me.alumnoData) 
      if(respuesta.operacion){
        me.$store.state.errobj.errtitulo = 'Mensaje del Servidor:'
        me.$store.state.errobj.errtexto = respuesta.msgtexto;
        me.$store.state.errobj.errbox = true
      }else{
        me.$store.state.errobj.errtitulo = 'Error del Servidor:'
        me.$store.state.errobj.errtexto = respuesta.msgtexto;
        me.$store.state.errobj.errbox = true
      }
    },
    cancelar(){
      this.alumnoData.celular = this.auxData.celular;
      this.alumnoData.correo = this.auxData.correo;
      this.alumnoData.contrasena_new = '';
      this.alumnoData.contrasena_old = '';

      this.editando = false;
      this.deshabilitado=true; 
    }
  },
}
</script>

